// Custom Style
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
    margin-top: auto;
}

.no-wrap {
    white-space: nowrap;
}

.card-img-cover {
    object-fit: cover;
}

.card-img-contain {
    object-fit: contain;
}

.fa-stack-small {
    line-height: 1em; height: 1em; width: 1em;
}
