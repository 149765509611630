// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Custom Style
@import 'custom';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome Icons
@import url("https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css");

// Vue Loading Overlay
@import '~vue-loading-overlay/dist/vue-loading.css';
